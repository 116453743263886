import React, { useState } from "react";
import Logo from "../assets/logo/logo-white.png";
import Profil from "../assets/icons/profil-white.png";
import Home from "../assets/icons/home-white.png";
import { useTranslation } from "react-i18next";
import FrenchFlag from "../assets/flags/fr.svg";
import EnglishFlag from "../assets/flags/en.svg";

export const NavBar = () => {
  const { t, i18n } = useTranslation();

  const [currentLanguage, setCurrentLanguage] = useState(
    localStorage.getItem("locale") || "fr"
  );

  const switchLanguage = () => {
    const newLanguage = currentLanguage === "en" ? "fr" : "en";
    i18n.changeLanguage(newLanguage);
    setCurrentLanguage(newLanguage);
    localStorage.setItem("locale", newLanguage);
  };

  if (
    window.location.pathname === "/login" ||
    window.location.pathname === "/register"
  ) {
    return <></>;
  }

  return (
    <div className="md:h-16 h-12 px-3 w-full bg-primary flex flex-row justify-between items-center">
      <div className="ml-2">
        <img src={Logo} alt="BASSIST' Logo" className="md:w-1/5 w-1/4" />
      </div>
      <div className="flex flew-row items-center md:gap-4 gap-6 md:mr-6 mr-2">
        <a
          href="/children"
          className="text-white flex flex-row items-center active:text-blue-500"
        >
          <img src={Home} alt="Home" className="h-auto md:w-10 w-24" />
          <p className="hidden md:hidden lg:block">{t("navbar.menu")}</p>
        </a>
        <a className="text-white flex flex-row items-center active:text-blue-500">
          <img src={Profil} alt="Profil" className="h-auto md:w-10 w-24" />
          <p className="hidden md:hidden lg:block">{t("navbar.profile")}</p>
        </a>
        <button
          onClick={switchLanguage}
          className="md:w-12 h-12 z-4 left-5 md:left-10 hover:brightness-105 transition-all"
        >
          <img
            src={currentLanguage === "fr" ? FrenchFlag : EnglishFlag}
            alt={currentLanguage === "fr" ? "French Flag" : "English Flag"}
            className="h-auto md:w-6 w-24 mx-auto my-auto"
          />
        </button>
      </div>
    </div>
  );
};
