import React from "react";

interface ButtonIconProps {
    text: string;
    color: string;
    urlRedirection: string;
    icon: string;
    width: string;
    disabled: boolean;
}

export const ButtonIcon: React.FC<ButtonIconProps> = ({
    text,
    color,
    urlRedirection,
    icon,
    width,
    disabled,
}) => {
    return (
        <button
            className={`flex flex-row items-center md:h-20 lg:h-16 h-14 ${width ? `w-${width}` : "w-full"} px-10 py-2 md:my-6 my-3 border-4 rounded-full ${
                disabled
                    ? "bg-gray-300 border-gray-500 cursor-not-allowed"
                    : `bg-white border-primary hover:border-${color}`
            } ${disabled ? "text-gray-700" : `text-${color}`} `}
            onClick={() => !disabled && (window.location.href = urlRedirection)}
            disabled={disabled}
        >
            <img
                src={icon}
                alt={"button : " + text}
                className="w-12 h-12 md:w-16 md:h-16 "
            />
            <span
                className={`md:text-lg lg:text-xl xl:text-xl text-md font-bold items-start ${
                    disabled ? "text-gray-500" : `text-${color}`
                }`}
            >
                {text}
            </span>
        </button>
    );
};

interface ButtonTextProps {
    text: string;
    color: string;
    onclick: () => void;
}

export const ButtonText: React.FC<ButtonTextProps> = ({
    text,
    color,
    onclick,
}) => {
    return (
        <button
            className={`flex flex-row items-center justify-center md:py-4 py-2 md:px-8 px-4 bg-white rounded-full cursor-pointer border shadow-xs transition-all duration-500 hover:border-${color} active:bg-${color} `}
            onClick={onclick}
        >
            <span
                className={`md:text-2xl text-md text-black transition-all duration-500 hover:text-${color} active:text-white`}
            >
                {text}
            </span>
        </button>
    );
};
