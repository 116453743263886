/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { WaveTitle } from "../component/WaveTitle";
import { ChildCard } from "../component/ChildCard";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAuth } from "../hooks/useAuth";
import axios from "../lib/config/axios";

import ArrowLeft from "../assets/icons/Arrow/ArrowLeft";
import ArrowRight from "../assets/icons/Arrow/ArrowRight";

// import { GridExample } from '../component/Tables';

const availablesMonitorings = [
  {
    id: 1,
    clickable_url: (id: string) => `/temperature/${id}`,
    field_name: "temperature",
    name: "Temperature",
    form: {
      fields: [
        {
          field_name: "degrees",
          name: "Degrès",
          type: "number",
        },
        // Méthode de prise aussi ?
      ],
    },
    getUrl: (id: string) => `/children/${id}/temperature-monitoring`,
    postUrl: (id: string) => `/children/${id}/temperature-monitoring`,
  },
  {
    id: 2,
    clickable_url: (id: string) => `/treatment/${id}`,
    field_name: "medication",
    name: "Médicaments",
    form: {
      fields: [
        {
          field_name: "medication",
          name: "Nom",
          type: "text",
        },
        {
          field_name: "dosage",
          name: "Quantité",
          type: "text",
        },
      ],
    },
    getUrl: (id: string) => `/children/${id}/medication-monitoring`,
    postUrl: (id: string) => `/children/${id}/medication-monitoring`,
  },
  {
    id: 3,
    clickable_url: (id: string) => `/feeding/${id}`,
    field_name: "feeding",
    name: "Alimentation",
    form: {
      fields: [
        // en ml
        {
          field_name: "quantity",
          name: "Quantité",
          type: "number",
        },
        // {
        //   name: "Type",
        //   type: "select",
        //   options: ["Lait", "Solide"],
        // },
      ],
    },
    getUrl: (id: string) => `/children/${id}/feeding-monitoring`,
    postUrl: (id: string) => `/children/${id}/feeding-monitoring`,
  },
  {
    id: 4,
    clickable_url: (id: string) => `/weight/${id}`,
    field_name: "weight",
    name: "Poids",
    form: {
      fields: [
        {
          field_name: "value",
          name: "Poids",
          type: "number",
        },
      ],
    },
    getUrl: (id: string) => `/children/${id}/weight-monitoring`,
    postUrl: (id: string) => `/children/${id}/weight-monitoring`,
  },
  {
    id: 5,
    clickable_url: (id: string) => `/vomiting/${id}`,
    field_name: "vomiting",
    name: "Vomissements",
    form: {
      fields: [
        {
          field_name: "quantity",
          name: "Quantité",
          type: "select",
          options: ["Peu", "Moyen", "Beaucoup"],
        },
        {
          field_name: "description",
          name: "Description",
          type: "text",
        },
      ],
    },
    getUrl: (id: string) => `/children/${id}/vomiting-monitoring`,
    postUrl: (id: string) => `/children/${id}/vomiting-monitoring`,
  },
  {
    id: 6,
    clickable_url: (id: string) => `/poop/${id}`,
    field_name: "stool",
    name: "Selles",
    form: {
      fields: [
        {
          field_name: "consistency",
          name: "Type",
          type: "select",
          options: ["Dure", "Molle", "Liquide"],
        },
        {
          field_name: "color",
          name: "Couleur",
          type: "select",
          options: ["Jaune", "Vert", "Noir", "Rouge"],
        },
        {
          field_name: "quantity",
          name: "Quantité",
          type: "select",
          options: ["Peu", "Moyen", "Beaucoup"],
        },
      ],
    },
    getUrl: (id: string) => `/children/${id}/stool-monitoring`,
    postUrl: (id: string) => `/children/${id}/stool-monitoring`,
  },
];

const MonitorModal = ({
  setOpen,
  carePlanDate,
  hour,
  monitoring,
  id,
  user,
}: any) => {
  const [form, setForm] = useState<any>({});

  const matchingForm = availablesMonitorings.find(
    (m) => m.name === monitoring
  )?.form;

  const monitoringDate = new Date(carePlanDate);
  monitoringDate.setHours(hour);
  monitoringDate.setMinutes(0);
  monitoringDate.setSeconds(0);

  const postUrl = availablesMonitorings
    .find((m) => m.name === monitoring)
    ?.postUrl(id);

  const handleSubmit = async (e: any) => {
    e.preventDefault();
    console.log({ ...form, dateTime: monitoringDate.toISOString() });

    if (!postUrl) return;
    const response = await axios.post(
      postUrl,
      { ...form, dateTime: monitoringDate.toISOString() },
      {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      }
    );

    if (response.status === 201) {
      setOpen(false);
    } else {
      console.log("error");
    }
  };

  return (
    <div className="fixed inset-0 z-50 flex justify-center items-center bg-black bg-opacity-50">
      <div className="bg-white w-2/3 md:w-1/2 xl:w-1/3 p-8 rounded-lg">
        <div className="flex justify-between items-center">
          <h2 className="text-lg font-semibold">
            Nouvelle donnée : {monitoring}
          </h2>
          <button onClick={() => setOpen(false)}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="h-6 w-6"
              fill="none"
              viewBox="0 0 24 24"
              stroke="currentColor"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d="M6 18L18 6M6 6l12 12"
              />
            </svg>
          </button>
        </div>
        <form className="flex flex-col mt-4 gap-4" onSubmit={handleSubmit}>
          {matchingForm?.fields.map((field: any) => (
            <div key={field.name} className="flex flex-col gap-2">
              <label htmlFor={field.name}>{field.name}</label>
              {field.type === "select" ? (
                <select
                  value={form[field.field_name]}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      [field.field_name]: e.target.value,
                    })
                  }
                  id={field.name}
                  name={field.name}
                  className="border border-gray-300 rounded-md p-2"
                >
                  {field.options.map((option: string) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>
              ) : (
                <input
                  value={form[field.field_name]}
                  onChange={(e) =>
                    setForm({
                      ...form,
                      [field.field_name]:
                        field.type === "number"
                          ? Number(e.target.value)
                          : e.target.value,
                    })
                  }
                  type={field.type}
                  id={field.name}
                  name={field.name}
                  className="border border-gray-300 rounded-md p-2"
                />
              )}
            </div>
          ))}
          <button
            type="submit"
            className="bg-primary text-white rounded-md p-2"
          >
            Ajouter
          </button>
        </form>
      </div>
    </div>
  );
};

export const CarePlan = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  // const current = new Date();
  // const date = `${current.getDate()}/${current.getMonth() + 1}/${current.getFullYear()}`;

  const [carePlanDate, setCarePlanDate] = useState(new Date());
  const [hour, setHour] = useState(-1);
  const [monitoring, setMonitoring] = useState("");
  const [open, setOpen] = useState(false);
  const [currentCarePlan, setCurrentCarePlan] = useState<any>([]);
  const [monitorings, setMonitorings] = useState<any>({});

  const handleNextDate = () => {
    setCarePlanDate(new Date(carePlanDate.setDate(carePlanDate.getDate() + 1)));
  };

  const handlePreviousDate = () => {
    setCarePlanDate(new Date(carePlanDate.setDate(carePlanDate.getDate() - 1)));
  };

  useEffect(() => {
    const fetchConsultations = async () => {
      const response = await axios.get(`/children/${id}/consultations/`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });

      const carePlanTitles = [
        ...new Set(
          response.data.flatMap((obj: any) =>
            obj.pathology.carePlans.map((plan: any) => plan.title)
          )
        ),
      ];

      // 2. Filter availablesMonitorings based on carePlanTitles
      const filteredMonitorings = availablesMonitorings.filter((monitoring) =>
        carePlanTitles.includes(monitoring.name)
      );

      setCurrentCarePlan(filteredMonitorings);
    };

    const fetchCurrentMonitorings = async () => {
      for (const monitoring of availablesMonitorings) {
        // @ts-expect-error maybe undefined
        const response = await axios.get(monitoring.getUrl(id), {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        console.log(response);

        setMonitorings((prev: any) => ({
          ...prev,
          [monitoring.field_name]: response.data,
        }));
      }
    };

    fetchConsultations();
    fetchCurrentMonitorings();
  }, []);

  const handleToggleModal = (hour: number, monitoring: string) => {
    console.log(hour, monitoring);
    setHour(hour);
    setMonitoring(monitoring);
    setOpen(!open);
  };

  return (
    <div className="w-screen">
      {open && (
        <MonitorModal
          setOpen={setOpen}
          carePlanDate={carePlanDate}
          hour={hour}
          monitoring={monitoring}
          id={id}
          user={user}
        />
      )}
      <section className="flex flex-col justify-between px-12 w-full">
        <div className="flex flex-row items-center justify-between">
          <WaveTitle
            color="blue"
            title={t("titles.care_plan")}
            titleColor="black"
          />
          <div className="flex flex-row gap-4">
            <ChildCard
              picture={child.image_id}
              name={child.firstname}
              urlRedirection={`/child-profil/${child.id}`}
              hoverColor="primary"
              height="h-40"
              fontSize="md"
            />
          </div>
        </div>
        <div className="flex justify-center items-start gap-x-8 my-4">
          <button
            className="rounded-xl hover:bg-gray-100 p-2"
            onClick={() => handlePreviousDate()}
          >
            <ArrowLeft />
          </button>
          <h2 className="mb-4 font-semibolf text-primary text-2xl">
            {new Date(carePlanDate).toLocaleDateString("fr-FR", {
              weekday: "long",
              year: "numeric",
              month: "long",
              day: "numeric",
            })}
          </h2>
          <button
            className="rounded-xl hover:bg-gray-100 p-2"
            onClick={() => handleNextDate()}
          >
            <ArrowRight />
          </button>
        </div>
        <div className="min-w-full inline-block align-middle">
          <div className="overflow-x-scroll border rounded-lg border-gray-300">
            <table className="min-w-full w-1/2 text-sm rounded-xl text-left rtl:text-right text-gray-500">
              <thead className="text-xs text-white uppercase bg-primary">
                <tr>
                  <th
                    scope="col"
                    className="sticky bg-primary left-0 z-1 min-w-60 pl-6 pr-3 py-3"
                  >
                    {t("care_plan.table_title")}
                  </th>
                  {Array.from({ length: 24 }).map((_, index) => (
                    <th
                      scope="col"
                      key={index}
                      className="px-6 py-3 font-medium text-white whitespace-nowrap"
                    >
                      {index}h00
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {currentCarePlan.map((monitoring: any) => (
                  <tr className="bg-white border-b" key={monitoring.id}>
                    <th
                      scope="row"
                      className="sticky left-0 z-20 px-6 py-4 text-white bg-pink-400 text-lg"
                    >
                      <a
                        className="hover:underline"
                        href={
                          window.location.protocol +
                          "//" +
                          window.location.host +
                          monitoring.clickable_url(id)
                        }
                      >
                        {monitoring.name}
                      </a>
                    </th>
                    {Array.from({ length: 24 }).map((_, index) => (
                      <td
                        key={index}
                        className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap hover:bg-gray-100"
                        onClick={() =>
                          handleToggleModal(index, monitoring.name)
                        }
                      >
                        {monitorings[monitoring.field_name]?.find(
                          (m: any) =>
                            new Date(m.dateTime).getHours() === index &&
                            new Date(m.dateTime).toLocaleDateString("fr-FR") ===
                              carePlanDate.toLocaleDateString("fr-FR")
                        ) ? (
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            className="h-6 w-6 text-green-500"
                            fill="none"
                            viewBox="0 0 24 24"
                            stroke="currentColor"
                          >
                            <path
                              strokeLinecap="round"
                              strokeLinejoin="round"
                              strokeWidth="2"
                              d="M5 13l4 4L19 7"
                            />
                          </svg>
                        ) : (
                          <div className="opacity-30">
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              className="h-6 w-6 text-gray-500"
                              fill="none"
                              viewBox="0 0 24 24"
                              stroke="currentColor"
                            >
                              <path
                                strokeLinecap="round"
                                strokeLinejoin="round"
                                strokeWidth="2"
                                d="M12 6v6m0 0v6m0-6h6m-6 0H6"
                              />
                            </svg>
                          </div>
                        )}
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </section>
    </div>
  );
};
