import React, { useState, useEffect, Dispatch, SetStateAction } from "react";
import "./TableCheck.scss";
import CheckSVG from "../../assets/icons/Check";
import PendingSVG from "../../assets/icons/Pending";
import { table } from "console";

interface TableCheckProps {
  tableList: any[];
  data: any[];
  setData: any;
  columns: { key: string; value: string }[];
  lines: { key: string; value: string }[];
  columnsName: { key: string; title: string };
}

const TableCheck: React.FC<TableCheckProps> = ({
  tableList,
  data,
  setData,
  columns,
  lines,
  columnsName,
}) => {
  const [cellStatus, setCellStatus] = useState<{
    [key: string]: "Completed" | "Pending" | "";
  }>({});

  useEffect(() => {
    const initialStatus: { [key: string]: "Completed" | "Pending" | "" } = {};
    tableList.forEach((d) => {
      const key = `${d.columnsName}`;
      initialStatus[key] = "Pending";
    });
    setCellStatus(initialStatus);
  }, [tableList]);

  const handleToggle = (line: string, col: string) => {
    const key = `${line}-${col}`;

    const idxExist = data.findIndex((d) => d.columnsName === key);

    if (idxExist > -1) {
      setData((prevItems: any[]) =>
        prevItems.filter((_, idx) => idx !== idxExist)
      );
    } else {
      const item = {
        vaccinationId: tableList.find((t) => t.columnsName === key)?.id,
        columnsName: key,
        status: "DONE",
        vaccineDate: new Date().toISOString(),
      };
      setData([...data, item]);
    }
    setCellStatus((prev) => ({
      ...prev,
      [key]: prev[key] === "Completed" ? "Pending" : "Completed",
    }));
  };

  const renderCell = (line: string, col: string) => {
    const key = `${line}-${col}`;
    const iconByStatus = {
      Completed: <CheckSVG />,
      Pending: <PendingSVG />,
      "": "",
    };
    return (
      <td
        key={key}
        className={
          cellStatus[key] === "Pending" || cellStatus[key] === "Completed"
            ? "cell"
            : "cell-disabled"
        }
        onClick={
          cellStatus[key] === "Pending" || cellStatus[key] === "Completed"
            ? () => handleToggle(line, col)
            : () => ({})
        }
      >
        {iconByStatus[cellStatus[key]]}
      </td>
    );
  };

  return (
    <div className="table-div">
      <table className="check-table">
        <thead>
          <tr>
            <th>{columnsName.title}</th>
            {columns.map((col) => (
              <th key={col.key}>{col.value}</th>
            ))}
          </tr>
        </thead>
        <tbody>
          {lines.map((line) => (
            <tr key={line.key}>
              <td>{line.value}</td>
              {columns.map((col) => renderCell(line.key, col.key))}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TableCheck;
