import React from "react";
import { WaveTitle } from "../../component/WaveTitle";
import { ChildCard } from "../../component/ChildCard";
import { ButtonIcon } from "../../component/Button";
import Temperature from "../../assets/icons/46-removebg-preview.png";
import ToiletPaper from "../../assets/icons/47-removebg-preview.png";
import Vomit from "../../assets/icons/48-removebg-preview.png";
import Bottle from "../../assets/icons/51-removebg-preview.png";
import Camera from "../../assets/icons/73-removebg-preview.png";
import Pills from "../../assets/icons/56-removebg-preview.png";
import Balance from "../../assets/icons/58-removebg-preview.png";
import Add from "../../assets/icons/72-removebg-preview.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

export const Monitoring = () => {
    const { t } = useTranslation();
    const { id } = useParams();
    const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
    return (
        <div className="w-screen">
            {/* <NavBar /> */}
            <section className="flex flex-col justify-between px-12 w-full">
                <div className="flex flex-row items-center justify-between">
                    <div className="flex flex-col">
                        <WaveTitle
                            color="pink"
                            title={t("titles.monitoring")}
                            titleColor="black"
                        />
                        <h2 className="font-semibold text-lg">
                            {t("monitoring.main_title")}
                        </h2>
                    </div>
                    <ChildCard
                        picture={child.image_id}
                        name={child.firstname}
                        urlRedirection={`/child-profil/${child.id}`}
                        hoverColor="primary"
                        height="h-40"
                        fontSize="md"
                    />
                </div>
                {/* <div className="flex flex-row items-center justify-evenly gap-4 py-12">
                    <ButtonIcon
                        text={t("monitoring.button_text.temperature")}
                        color="black"
                        urlRedirection={`/temperature/${child.id}`}
                        icon={Temperature}
                        width="1/3"
                        disabled={false}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.treatment")}
                        color="black"
                        urlRedirection={`/treatment/${child.id}`}
                        icon={Pills}
                        width="1/3"
                        disabled={false}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.vomiting")}
                        color="black"
                        urlRedirection={`/vomiting/${child.id}`}
                        icon={Vomit}
                        width="1/3"
                        disabled={false}
                    />
                </div>
                <div className="flex flex-row items-center justify-evenly gap-4 py-12">
                    <ButtonIcon
                        text={t("monitoring.button_text.feeding")}
                        color="black"
                        urlRedirection={`/feeding/${child.id}`}
                        icon={Bottle}
                        width="1/3"
                        disabled={false}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.images")}
                        color="black"
                        urlRedirection="/consultation"
                        icon={Camera}
                        width="1/3"
                        disabled={true}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.poop")}
                        color="black"
                        urlRedirection={`/poop/${child.id}`}
                        icon={ToiletPaper}
                        width="1/3"
                        disabled={false}
                    />
                </div>
                <div className="flex flex-row items-center justify-evenly gap-4 py-12">
                    <ButtonIcon
                        text={t("monitoring.button_text.weight")}
                        color="black"
                        urlRedirection={`/weight/${child.id}`}
                        icon={Balance}
                        width="1/3"
                        disabled={false}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.new_monitoring")}
                        color="black"
                        urlRedirection="/monitoring"
                        icon={Add}
                        width="1/3"
                        disabled={false}
                    />
                </div> */}
                <div className="grid xl:grid-cols-3 md:grid-cols-2 gap-16 pt-20 xl:px-36">
                    <ButtonIcon
                        text={t("monitoring.button_text.temperature")}
                        color="black"
                        urlRedirection={`/temperature/${child.id}`}
                        icon={Temperature}
                        width="1/3"
                        disabled={false}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.treatment")}
                        color="black"
                        urlRedirection={`/treatment/${child.id}`}
                        icon={Pills}
                        width="1/3"
                        disabled={false}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.vomiting")}
                        color="black"
                        urlRedirection={`/vomiting/${child.id}`}
                        icon={Vomit}
                        width="1/3"
                        disabled={false}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.feeding")}
                        color="black"
                        urlRedirection={`/feeding/${child.id}`}
                        icon={Bottle}
                        width="1/3"
                        disabled={false}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.poop")}
                        color="black"
                        urlRedirection={`/poop/${child.id}`}
                        icon={ToiletPaper}
                        width="1/3"
                        disabled={false}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.weight")}
                        color="black"
                        urlRedirection={`/weight/${child.id}`}
                        icon={Balance}
                        width="1/3"
                        disabled={false}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.images")}
                        color="black"
                        urlRedirection="/consultation"
                        icon={Camera}
                        width="1/3"
                        disabled={true}
                    />
                    <ButtonIcon
                        text={t("monitoring.button_text.new_monitoring")}
                        color="black"
                        urlRedirection="/monitoring"
                        icon={Add}
                        width="1/3"
                        disabled={true}
                    />
                </div>
            </section>
        </div>
    );
};
