import React, { useMemo, useState } from "react";
import "./Table.scss";
import AddSVG from "../../assets/icons/Add";
import { t } from "i18next";
// Composant pour une ligne de tableau
const TableRow = ({
  columns,
  onDelete,
}: {
  columns: any[];
  onDelete: (idx: number) => void;
}) => (
  <tr>
    {columns.map((col, index) => (
      <td key={index}>
        <div>
          {col}
          <div onClick={() => onDelete(index)}>
            {/* {index == 0 && <CrossSVG />} */}
          </div>
        </div>
      </td>
    ))}
  </tr>
);

// Composant pour le tableau dynamique
const Table = ({
  title,
  data,
  columns,
  allergyList,
  manifestationList,
  medicineList,
  add,
  remove,
}: {
  title: string;
  data: any[];
  columns: any[];
  allergyList?: { id: number; name: string }[];
  manifestationList?: { id: string; name: string }[];
  medicineList?: any[];
  add: (item: never) => void;
  remove: (data: never) => void;
}) => {
  const [viewNewItem, setViewNewItem] = useState(false);
  // const [formValue, setFormValue] = useState([...data]);
  const [newItem, setNewItem] = useState({});

  const canAddItem = useMemo(
    () =>
      Object.keys(newItem).length == columns.length &&
      Object.values(newItem).filter((n) => n != "").length ==
        Object.keys(newItem).length,
    [newItem, columns.length]
  );

  const onConfirm = () => {
    add(newItem as never);
    setNewItem({});
    setViewNewItem(false);
  };

  const onDelete = (idx: number) => {
    const newData = data.filter((_, i) => i !== idx);
    remove(newData as never);
  };

  const onChangeData = (type: string, value: string) => {
    setNewItem({ ...newItem, [type]: value });
  };

  return (
    <div className="table-container">
      <h2>{title}</h2>
      <div className="styled-background-table">
        <table className="styled-table">
          <thead>
            <tr>
              {columns.map((col, index) => (
                <th key={index}>{col.header.toUpperCase()}</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {data.map((row, rowIndex) => (
              <TableRow
                key={rowIndex}
                columns={columns.map((col) => row[col.accessor])}
                onDelete={onDelete}
              />
            ))}
          </tbody>
        </table>
      </div>
      {viewNewItem ? (
        <div className="add-container">
          {columns.map((col, idx) => (
            <div className="add-item" key={idx}>
              <p>{col.header}</p>
              {col.component == "input" ? (
                <input
                  type={col.type}
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                  // required
                  placeholder={col.placeholder}
                />
              ) : (
                <select
                  onChange={(e) => onChangeData(col.accessor, e.target.value)}
                  // required
                >
                  <option value={"chooseOption"} disabled selected>
                    {t("new_child.form-data.placeholder.chooseOption")}
                  </option>
                  {(col.type === "allergyList"
                    ? allergyList
                    : col.type === "manifestationList"
                      ? manifestationList
                      : medicineList
                  )?.map((a: any) => (
                    <option key={a.id ?? a} value={a?.name ?? a}>
                      {a?.name ?? a}
                    </option>
                  ))}
                </select>
              )}
            </div>
          ))}
          <button
            className="add-button"
            type="button"
            onClick={onConfirm}
            disabled={!canAddItem}
          >
            <AddSVG />
            <span>AJOUTER</span>
          </button>
        </div>
      ) : (
        <button
          className="add-button"
          type="button"
          onClick={() => setViewNewItem(true)}
        >
          <AddSVG />
          <span>AJOUTER</span>
        </button>
      )}
    </div>
  );
};

export default Table;
