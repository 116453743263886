import React, { useEffect, useState } from "react";
import { WaveTitle } from "../component/WaveTitle";
import { ChildCard } from "../component/ChildCard";
import { ButtonIcon } from "../component/Button";
import Checklist from "../assets/icons/67-removebg-preview.png";
import MagnifyingGlass from "../assets/icons/66-removebg-preview.png";
import Stethoscope from "../assets/icons/65-removebg-preview.png";
import Admin from "../assets/icons/68-removebg-preview.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import axios from "../lib/config/axios";
import { useAuth } from "../hooks/useAuth";
import { PDFDownloadLink } from "@react-pdf/renderer";
import { AboutChildPdf } from "./AboutChildPdf";

interface ChildInfos {
  child: {
    id: number;
    firstname: string;
    birthdate: string;
    gender: string;
    createdAt: string;
    firstParentId: number;
    secondParentId: number;
    gestionalAgeAtBirth: number;
    image_id: string;
    updatedAt: string;
  };
  size: {
    id: number;
    childId: number;
    value: number;
    dateTime: string;
  };
  weight: {
    id: number;
    childId: number;
    value: number;
    dateTime: string;
  };
}

const monthDiff = (startDate: Date, endDate: Date) =>
  Math.max(
    0,
    (endDate.getFullYear() - startDate.getFullYear()) * 12 -
      startDate.getMonth() +
      endDate.getMonth()
  );

export const AboutChild = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");
  const [childInfos, setChildInfos] = useState<ChildInfos | null>(null);
  const [childBackgrounds, setChildBackgrounds] = useState<any | null>(null);

  const fetchChildInfos = async () => {
    const fetch = await axios.get(`/children/${id}`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });

    if (fetch.status === 200 && fetch.data) {
      console.log("infos", fetch.data);
      setChildInfos(fetch.data);
      // localStorage.setItem(id ?? "", JSON.stringify(fetch.data));
    }
  };

  const fetchChildBackgrounds = async () => {
    const fetch = await axios.get(`/children/${id}/all-data`, {
      headers: {
        Authorization: `Bearer ${user}`,
      },
    });

    if (fetch.status === 200 && fetch.data) {
      console.log("backgrounds", fetch.data);
      setChildBackgrounds(fetch.data);
    }
  };

  useEffect(() => {
    fetchChildInfos();
    fetchChildBackgrounds();
    // console.log(child);
    // Fetch data from API
    // Save data to localStorage
    // localStorage
  }, []);

  return (
    <div className="w-full h-full">
      <section className="flex lg:flex-row md:flex-col ">
        <div className="flex flex-1 flex-col lg:py-6 px-10 ">
          <WaveTitle
            color="blue"
            title={t("titles.about_child")}
            titleColor="black"
          />
          <div className="flex flex-col items-center justify-center mt-12">
            <ChildCard
              picture={child.image_id}
              name={child.firstname}
              urlRedirection={`/child-profil/${child.id}`}
              hoverColor="primary"
              height="md:h-60 h-32"
              fontSize="xl"
            />
            <div className="flex flex-row w-1/2 mt-6 items-center justify-evenly gap-8">
              <div className="flex flex-col items-center ">
                <p className="text-primary font-bold xl:text-2xl text-lg">
                  {t("mesure.age")}
                </p>
                <span className="text-gray-900 xl:text-xl text-md">
                  {/* @ts-e
                  {monthDiff(
                    new Date(childInfos.child.birthdate),xpect-error maybe undefined */}
                  {monthDiff(
                    new Date(childInfos?.child.birthdate || new Date()),
                    new Date()
                  )}{" "}
                  mois
                </span>
              </div>
              <div className="flex flex-col items-center ">
                <p className="text-primary font-bold xl:text-2xl text-lg">
                  {t("mesure.weight")}
                </p>
                <span className="text-gray-900 xl:text-xl text-md">
                  {childInfos?.weight.value} kg
                </span>
              </div>
              <div className="flex flex-col items-center ">
                <p className="text-primary font-bold xl:text-2xl text-lg">
                  {t("mesure.height")}
                </p>
                <span className="text-gray-900 xl:text-xl text-md">
                  {childInfos?.size.value} cm
                </span>
              </div>
            </div>
            <div className="flex flex-row w-1/2 mt-6 items-center justify-evenly gap-8">
              <div className="flex flex-col items-center">
                <PDFDownloadLink
                  document={
                    <AboutChildPdf
                      childInfos={childInfos}
                      childBackgrounds={childBackgrounds}
                    />
                  }
                  fileName={`je-dis-quoi-au-docteur-${childInfos?.child.firstname}-${new Date().toLocaleDateString()}.pdf`}
                >
                  <button className="bg-primary hover:opacity-90 text-white lg:text-lg text-md md:text-md font-bold xl:h-10 md:px-12 md:h-12 lg:h-14 lg:w-full rounded-full">
                    Je dis quoi au docteur ?
                  </button>
                </PDFDownloadLink>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-1 flex-col items-start md:gap-4 gap-2 mt-12 md:items-center md:justify-center xl:justify-end">
          <div className="w-4/5">
            <ButtonIcon
              text={t("titles.consultation")}
              color="black"
              urlRedirection={`/consultation/${id}`}
              icon={Stethoscope}
              width="full"
              disabled={false}
            />
            <ButtonIcon
              text={t("titles.monitoring")}
              color="black"
              urlRedirection={`/monitoring/${id}`}
              icon={MagnifyingGlass}
              width="full"
              disabled={false}
            />
            <ButtonIcon
              text={t("titles.care_plan")}
              color="black"
              urlRedirection={`/careplan/${id}`}
              icon={Checklist}
              width="full"
              disabled={false}
            />
            <ButtonIcon
              text={t("titles.administrative")}
              color="black"
              urlRedirection="/administratif"
              icon={Admin}
              width="full"
              disabled={false}
            />
          </div>
        </div>
      </section>
    </div>
  );
};
