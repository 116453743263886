import React, { useEffect, useState } from "react";
import { WaveTitle } from "../component/WaveTitle";
import { ChildCard } from "../component/ChildCard";
import { ButtonIcon } from "../component/Button";
import Look from "../assets/icons/loupe-green.png";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { CardMonitoring } from "../component/Cards";
import axios from "../lib/config/axios";
import { useAuth } from "../hooks/useAuth";

interface Pathology {
  id: number;
  name: string;
  description?: string;
}

interface Consultation {
  date: string;
  pathology: string;
  comment: string;
  treatment_start_date: string;
  treatment_end_date: string;
}

interface ChildPathologies {
  id: number;
  pathologyId: number;
  date: string;
  notes: string;
  startDate: string;
  endDate: string;
}

export const Consultation = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { id } = useParams();
  const child = JSON.parse(localStorage.getItem(id ?? "") || "{}");

  const [form, setForm] = useState({
    date: "",
    pathology: "",
    comment: "",
    treatment_start_date: "",
    treatment_end_date: "",
  });
  const [pathologiesList, setPathologiesList] = useState<Pathology[]>([]);

  const [childPathologiesList, setChildPathologies] = useState<
    ChildPathologies[]
  >([]);

  useEffect(() => {
    const fetchPathologies = async () => {
      const response = await axios.get("/pathologies", {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });
      const data = response.data;
      setPathologiesList(
        data.sort((a: Pathology, b: Pathology) => a.name.localeCompare(b.name))
      );
    };

    const childPathologies = async () => {
      const response = await axios.get(`/children/${child.id}/consultations`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      });
      const data = response.data;
      console.log(data);
      setChildPathologies(data);
    };
    fetchPathologies();
    childPathologies();
  }, []);

  const handleSubmit = async () => {
    const selectedPathology = pathologiesList.find(
      (p) => p.name === form.pathology
    );

    if (!selectedPathology) {
      console.error("Pathology not selected properly.");
      return;
    }

    try {
      await axios.post(
        `/children/${child.id}/consultations`,
        {
          consultations: [
            {
              pathologyId: selectedPathology.id,
              date: form.date,
              notes: form.comment,
              startDate: form.treatment_start_date,
              endDate: form.treatment_end_date,
            },
          ],
        },
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      );
      console.log("Consultation added successfully");
    } catch (error) {
      console.error("Error adding consultation:", error);
    }
  };

  return (
    <div className="w-screen">
      <section className="flex flex-col justify-between px-6 md:px-12 w-full">
        <div className="flex flex-col md:flex-row items-center justify-between">
          <WaveTitle
            color="green"
            title={t("titles.consultation")}
            titleColor="green-500"
          />
          <div className="flex flex-row gap-4 mt-4 md:mt-0">
            <ChildCard
              picture={child.image_id}
              name={child.firstname}
              urlRedirection={`/child-profil/${child.id}`}
              hoverColor="primary"
              height="h-40"
              fontSize="md"
            />
          </div>
        </div>

        <div className="mt-8 mx-4">
          <h2 className="text-xl font-semibold mb-4">
            {t("consultation.new_consultation")}
          </h2>
          <form
            className="grid grid-cols-1 sm:grid-cols-2 gap-4 my-12"
            onSubmit={handleSubmit}
          >
            <div className="flex flex-col items-start w-full">
              <label className="block mb-2 text-lg font-semibold text-darkgrey">
                {t("mesure.date")}
              </label>
              <input
                type="date"
                className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full md:w-2/3 lg:w-4/5 p-2.5"
                value={form.date}
                onChange={(e) => setForm({ ...form, date: e.target.value })}
                required
              />
            </div>

            <div className="flex flex-col items-start w-full">
              <label className="block mb-2 text-lg font-semibold text-darkgrey">
                {t("consultation.table_header.scikness")}
              </label>
              <select
                value={form.pathology}
                onChange={(e) =>
                  setForm({
                    ...form,
                    pathology: e.target.value,
                  })
                }
                required
                className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full md:w-2/3 lg:w-4/5 p-2.5"
              >
                <option value={"chooseOption"} disabled selected>
                  {t("consultation.form-data.placeholder.chooseOption")}
                </option>
                {pathologiesList &&
                  pathologiesList.map((pathology) => (
                    <option key={pathology.id} value={pathology.name}>
                      {pathology.name}
                    </option>
                  ))}
              </select>
            </div>

            <div className="flex flex-col items-start w-full">
              <label className="block mb-2 text-lg font-semibold text-darkgrey">
                {t("consultation.table_header.comment")}
              </label>
              <input
                value={form.comment}
                onChange={(e) =>
                  setForm({
                    ...form,
                    comment: e.target.value,
                  })
                }
                type="text"
                className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full md:w-2/3 lg:w-4/5 p-2.5"
                required
              />
            </div>

            <div className="flex flex-col items-start w-full">
              <label className="block mb-2 text-lg font-semibold text-darkgrey">
                {t("consultation.table_header.treatment_start_date")}
              </label>
              <input
                type="date"
                className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full md:w-2/3 lg:w-4/5 p-2.5"
                value={form.treatment_start_date}
                onChange={(e) =>
                  setForm({
                    ...form,
                    treatment_start_date: e.target.value,
                  })
                }
                required
              />
            </div>

            <div className="flex flex-col items-start w-full">
              <label className="block mb-2 text-lg font-semibold text-darkgrey">
                {t("consultation.table_header.treatment_end_date")}
              </label>
              <input
                type="date"
                className="bg-white border-2 border-gray-300 text-gray-900 text-sm rounded-full focus:ring-blue-500 focus:border-blue-500 block w-full md:w-2/3 lg:w-4/5 p-2.5"
                value={form.treatment_end_date}
                onChange={(e) =>
                  setForm({
                    ...form,
                    treatment_end_date: e.target.value,
                  })
                }
                required
              />
            </div>

            <div className="flex flex-col items-center md:items-start md:justify-end">
              <button
                type="submit"
                className="xl:mt-4 flex items-center py-1 px-3 text-sm bg-green-500 text-white rounded-full cursor-pointer font-semibold text-center shadow-xs transition-all duration-500 hover:bg-green-600"
              >
                <svg
                  viewBox="0 0 24 24"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                  className="mr-1 h-6"
                >
                  <path
                    d="M12 6V18"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    className="my-path"
                  ></path>
                  <path
                    d="M18 12H6"
                    stroke="white"
                    strokeWidth="1.6"
                    strokeLinecap="round"
                    className="my-path"
                  ></path>
                </svg>
                {t("buttons.add")}
              </button>
            </div>
          </form>
        </div>

        <div className="mx-4 lg:mb-36">
          <h2 className="text-xl font-semibold mb-4">{t("titles.history")}</h2>
          <div className="grid md:grid-cols-2 lg:grid-cols-3 grid-cols-2 gap-8 mt-8">
            {childPathologiesList.map((c: ChildPathologies, index) => (
              <div key={index} className="flex items-center justify-center">
                <CardMonitoring
                  time={c.date.split("T")[0].split("-").reverse().join("/")}
                  icone="consultation"
                  isWarning={false}
                  title={
                    pathologiesList.find((p) => p.id === c.pathologyId)?.name ||
                    ""
                  }
                  description={c.notes}
                />
              </div>
            ))}
          </div>
        </div>

        <div className="fixed bottom-0 w-full flex justify-center">
          <ButtonIcon
            text="Trouver une pharmacie"
            color="green-500"
            urlRedirection="https://pharmacie-de-garde.org/"
            icon={Look}
            width="1/2 md:w-2/3 lg:w-1/3"
            disabled={false}
          />
        </div>
      </section>
    </div>
  );
};
