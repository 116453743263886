/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Table from "../../component/Table/Table";
import Tabs from "../../component/Tabs/Tabs";
import "./NewChild.scss";
import Carousel from "./components/Carousel";
import { useAuth } from "../../hooks/useAuth";
import axios from "../../lib/config/axios";
import TableCheck from "../../component/TableCheck/TableCheck";

type GlobalDataType = {
  firstname?: string;
  gender?: "FEMALE" | "MALE";
  birthdate?: Date;
  gestationalAgeAtBirth?: number;
  firstParentId?: number;
  image_id: number;
  size?: number;
  weight?: number;
};

// const MATCHING_MANIFESTATION: { [key: string]: string } = {
//   REDNESS: "Rougeur",
//   BUTTONS: "Boutons",
//   SWELLING: "Gonflement",
//   SKIN_ITCH: "Démangeaison",
//   RESPIRATORY_GENE: "Gêne respiratoire",
//   RHINORHEA: "Rhinite",
//   ITCHY_EYES: "Yeux qui piquent",
//   TEARING: "Larmoiement",
//   ETERNALLY: "Éternuements",
//   VOMITING: "Vomissements",
//   DIARRHEA: "Diarrhée",
//   OTHER: "Autre",
// };

const manifestationMatchingList = [
  { id: "REDNESS", name: "Rougeur" },
  { id: "BUTTONS", name: "Boutons" },
  { id: "SWELLING", name: "Gonflement" },
  { id: "SKIN_ITCH", name: "Démangeaison" },
  { id: "RESPIRATORY_GENE", name: "Gêne respiratoire" },
  { id: "RHINORHEA", name: "Rhinite" },
  { id: "ITCHY_EYES", name: "Yeux qui piquent" },
  { id: "TEARING", name: "Larmoiement" },
  { id: "ETERNALLY", name: "Éternuements" },
  { id: "VOMITING", name: "Vomissements" },
  { id: "DIARRHEA", name: "Diarrhée" },
  { id: "OTHER", name: "Autre" },
];

const NewChild = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const [activeTab, setActiveTab] = useState<string>("step1");
  const [globalData, setGlobalData] = useState<GlobalDataType>({
    firstname: undefined,
    gender: "FEMALE",
    birthdate: undefined,
    gestationalAgeAtBirth: undefined,
    firstParentId: undefined,
    image_id: 0,
    size: undefined,
    weight: undefined,
  });
  const [allergyList, setAllergyList] = useState([]);
  const [medicineList, setMedicineList] = useState([]);
  const [vaccinationsList, setVaccinationsList] = useState([]);
  const [vacinationData, setVacinationData] = useState([]);
  const [allergiesData, setAllergiesData] = useState([]);
  const [antecedentsData, setAntedecentsData] = useState([]);
  const [medicinesData, setMedicinesData] = useState([]);

  const vacinationColumns = [
    { key: "col1", value: t("new_child.form-data.vacinationColumns.col1") },
    { key: "col2", value: t("new_child.form-data.vacinationColumns.col2") },
    { key: "col3", value: t("new_child.form-data.vacinationColumns.col3") },
    { key: "col4", value: t("new_child.form-data.vacinationColumns.col4") },
    { key: "col5", value: t("new_child.form-data.vacinationColumns.col5") },
    { key: "col6", value: t("new_child.form-data.vacinationColumns.col6") },
    { key: "col7", value: t("new_child.form-data.vacinationColumns.col7") },
    { key: "col8", value: t("new_child.form-data.vacinationColumns.col8") },
    { key: "col9", value: t("new_child.form-data.vacinationColumns.col9") },
  ];

  const vacinationLines = [
    { key: "line1", value: t("new_child.form-data.vacinationLines.line1") },
    { key: "line2", value: t("new_child.form-data.vacinationLines.line2") },
    { key: "line3", value: t("new_child.form-data.vacinationLines.line3") },
    { key: "line4", value: t("new_child.form-data.vacinationLines.line4") },
    { key: "line5", value: t("new_child.form-data.vacinationLines.line5") },
    { key: "line6", value: t("new_child.form-data.vacinationLines.line6") },
    { key: "line7", value: t("new_child.form-data.vacinationLines.line7") },
    { key: "line8", value: t("new_child.form-data.vacinationLines.line8") },
  ];

  const tabs = [
    { label: t("new_child.stepper.step1"), value: "step1" },
    { label: t("new_child.stepper.step2"), value: "step2" },
    { label: t("new_child.stepper.step3"), value: "step3" },
    { label: t("new_child.stepper.step4"), value: "step4" },
    { label: t("new_child.stepper.step5"), value: "step5" },
  ];

  const allergiesColumns = [
    {
      header: t("mesure.date"),
      accessor: "date",
      type: "month",
      component: "input",
    },
    {
      header: t("new_child.stepper.step3"),
      accessor: "name",
      component: "select",
      type: "allergyList",
    },
    {
      header: t("new_child.form_label.manifestation"),
      accessor: "manifestation",
      component: "select",
      type: "manifestationList",
    },
  ];

  const antecedentsColumns = [
    {
      header: t("mesure.date"),
      accessor: "date",
      type: "month",
      component: "input",
    },
    {
      header: t("new_child.stepper.step2"),
      accessor: "name",
      type: "text",
      component: "input",
      placeholder: t("new_child.form-data.placeholder.antecedent"),
    },
  ];

  const medicationColumns = [
    {
      header: t("new_child.form_label.medication"),
      accessor: "medication",
      type: "medicineList",
      component: "select",
    },
    {
      header: t("new_child.form_label.period"),
      accessor: "dosage",
      type: "text",
      component: "input",
      placeholder: t("new_child.form-data.placeholder.medicine"),
    },
  ];

  useEffect(() => {
    const fetchParentProfile = async () => {
      try {
        const response = await axios.get("/users/profile", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const parent = response.data;
        setGlobalData({ ...globalData, firstParentId: parent.id });
      } catch (err) {
        console.error(err);
      }
    };
    const fetchAllergies = async () => {
      try {
        const response = await axios.get("/allergies", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const allergiesData = response.data;
        setAllergyList(allergiesData);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchVaccines = async () => {
      try {
        const response = await axios.get("/vaccinations", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const vaccineData = response.data;
        setVaccinationsList(vaccineData);
      } catch (err) {
        console.error(err);
      }
    };
    const fetchMedications = async () => {
      try {
        const response = await axios.get("/medicines", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        const medData = response.data;
        setMedicineList(medData);
      } catch (err) {
        console.error(err);
      }
    };
    fetchParentProfile();
    fetchAllergies();
    fetchVaccines();
    fetchMedications();
  }, [user]);

  const handleTabClick = (label: string) => {
    setActiveTab(label);
    const element = document.getElementById(label);
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
  };

  const onChangeGlobalData = (name: string, value: number | string) => {
    setGlobalData({ ...globalData, [name]: value });
  };

  const onChangeOtherData = (
    data: never,
    type: "allergy" | "antecedent" | "medicine"
  ) => {
    if (type == "allergy") {
      setAllergiesData([...allergiesData, data]);
    } else if (type == "antecedent") {
      setAntedecentsData([...antecedentsData, data]);
    } else if (type == "medicine") {
      setMedicinesData([...medicinesData, data]);
    }
  };

  const onValidateForm = (e: any) => {
    e.preventDefault();
    const newMedicinesData = medicinesData.map((med: any) => ({
      ...med,
      dateTime: new Date(Date.now()).toISOString(),
    }));

    const { size, weight, birthdate, ...childData } = globalData;
    const date = birthdate && new Date(birthdate);
    const timestamp = date?.toISOString();

    const modifiedAllergies = allergiesData.map((allergy) => ({
      // @ts-expect-error maybe undefined
      date: allergy.date,
      // @ts-expect-error maybe undefined
      allergyId: allergyList.find((a: any) => a.name === allergy.name).id,
      // @ts-expect-error maybe undefined
      manifestation: manifestationMatchingList.find(
        // @ts-expect-error maybe undefined
        (m: any) => m.name === allergy.manifestation
      ).id,
    }));

    const modifiedVacinations = vacinationData.map((vaccination) => ({
      // @ts-expect-error maybe undefined
      vaccinationId: vaccination.vaccinationId,
      // @ts-expect-error maybe undefined
      status: vaccination.status,
      // @ts-expect-error maybe undefined
      vaccineDate: vaccination.vaccineDate,
    }));

    const formValues = {
      ...childData,
      birthdate: timestamp,
      size,
      weight,
      medicalBackgrounds: antecedentsData,
      vaccinations: modifiedVacinations,
      medicationMonitorings: newMedicinesData,
      allergies: modifiedAllergies,
    };

    axios
      .post(
        "/children/create",
        {
          ...formValues,
        },
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      )
      .then(() => {
        window.location.href = "/children";
      });
  };

  return (
    <div className="new-child-container">
      <section>
        <Tabs tabs={tabs} activeTab={activeTab} onTabClick={handleTabClick} />
        <form onSubmit={onValidateForm}>
          <div id="step1">
            <h2>{t("new_child.stepper.step1")}</h2>
            <Carousel
              active={globalData.image_id}
              onChange={(idx) => onChangeGlobalData("image_id", idx)}
            />
            <div id="name-user">
              <div>
                <label>{t("new_child.form_label.firstname")}</label>
                <input
                  value={globalData.firstname}
                  required
                  onChange={(e) =>
                    onChangeGlobalData("firstname", e.target.value)
                  }
                />
              </div>
              <div>
                <label>{t("new_child.form_label.genre")}</label>
                <select
                  value={globalData.gender}
                  onChange={(e) => onChangeGlobalData("gender", e.target.value)}
                  required
                >
                  <option key="FEMALE" value="FEMALE">
                    {t("new_child.form_label.genres.female")}
                  </option>
                  <option key="MALE" value="MALE">
                    {t("new_child.form_label.genres.male")}
                  </option>
                </select>
              </div>
            </div>
            <div id="information-user">
              <div>
                <label>{t("new_child.form_label.birthdate")}</label>
                <input
                  required
                  type="date"
                  max={new Date().toISOString().split("T")[0]}
                  onChange={(e) =>
                    onChangeGlobalData("birthdate", e.target.value)
                  }
                />
              </div>
              <div>
                <label>{t("new_child.form_label.size")}</label>
                <input
                  type="number"
                  onChange={(e) =>
                    onChangeGlobalData("size", Number(e.target.value))
                  }
                />
              </div>
              <div>
                <label>{t("new_child.form_label.weight")}</label>
                <input
                  type="number"
                  step="0.1"
                  onChange={(e) =>
                    onChangeGlobalData("weight", Number(e.target.value))
                  }
                />
              </div>
              <div>
                <label>{t("new_child.form_label.gestation_time")}</label>
                <input
                  required
                  type="number"
                  onChange={(e) =>
                    onChangeGlobalData(
                      "gestationalAgeAtBirth",
                      Number(e.target.value)
                    )
                  }
                />
              </div>
            </div>
          </div>
          <div id="step2">
            <Table
              columns={antecedentsColumns}
              data={antecedentsData}
              title={t("new_child.stepper.step2")}
              add={(item: never) => onChangeOtherData(item, "antecedent")}
              remove={(data: never) => setAntedecentsData(data)}
            />
          </div>
          <div id="step3">
            <Table
              columns={allergiesColumns}
              data={allergiesData}
              title={t("new_child.stepper.step3")}
              add={(data: never) => onChangeOtherData(data, "allergy")}
              allergyList={allergyList}
              manifestationList={manifestationMatchingList}
              remove={(data: never) => setAllergiesData(data)}
            />
          </div>
          <div id="step4">
            <h2>{t("new_child.stepper.step4")}</h2>
            <TableCheck
              tableList={vaccinationsList}
              data={vacinationData}
              setData={setVacinationData}
              columns={vacinationColumns}
              lines={vacinationLines}
              columnsName={{
                key: "age",
                title: t("new_child.form_label.age"),
              }}
            />
          </div>
          <div id="step5">
            <Table
              columns={medicationColumns}
              data={medicinesData}
              title={t("new_child.stepper.step5")}
              add={(data: never) => onChangeOtherData(data, "medicine")}
              remove={(data: never) => setMedicinesData(data)}
              medicineList={medicineList}
            />
          </div>
          <div className="create-button">
            <button>{t("new_child.create_profile")}</button>
          </div>
        </form>
      </section>
    </div>
  );
};

export default NewChild;
