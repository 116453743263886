import React from "react";

interface TableProps {
    headers: string[];
    rows: (string | number)[][];
}

export const Table: React.FC<TableProps> = ({ headers, rows }) => {
    return (
        <div className="flex flex-col">
            <div className="overflow-x-auto">
                <div className="min-w-full inline-block align-middle">
                    <div className="overflow-hidden border rounded-lg border-gray-300">
                        <table className="min-w-full rounded-xl">
                            <thead className="divide-gray-300">
                                <tr className="bg-gray-50">
                                    {headers.map((header, index) => (
                                        <th
                                            key={index}
                                            scope="col"
                                            className="p-5 text-left text-sm leading-6 font-semibold text-gray-900 capitalize"
                                        >
                                            {header}
                                        </th>
                                    ))}
                                </tr>
                            </thead>
                            <tbody className="divide-y divide-gray-300 bg-white">
                                {rows.map((row, rowIndex) => (
                                    <tr key={rowIndex}>
                                        {row.map((cell, cellIndex) => (
                                            <td
                                                key={cellIndex}
                                                className="p-5 whitespace-nowrap text-sm leading-6 font-medium text-gray-900"
                                            >
                                                {cell}
                                            </td>
                                        ))}
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>
    );
};
