import React, { useState, useEffect } from "react";
import { NavBar } from "../../component/NavBar";
import { useTranslation } from "react-i18next";
import { useAuth } from "../../hooks/useAuth";
import { useSnackbar } from "notistack";
import axios from "../../lib/config/axios";
import { WaveTitle } from "../../component/WaveTitle";

interface Parent {
  pseudo?: string;
  email?: string;
}

export const ParentProfil = () => {
  const { t } = useTranslation();
  const { user, logout } = useAuth();
  const { enqueueSnackbar } = useSnackbar();
  const [parent, setParent] = useState<Parent>({});
  const [isToggled, setIsToggled] = useState(false);

  const firstLetter = parent.pseudo?.charAt(0).toUpperCase();

  const handleToggleChange = () => {
    setIsToggled(!isToggled);
  };

  const handleLogout = () => {
    axios
      .post(
        "/users/logout",
        {},
        {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        }
      )
      .then((response) => {
        if (response.status === 200) {
          logout();
          window.location.href = "/login";
        } else {
          throw new Error("Failed to logout");
        }
      })
      .catch(() => {
        enqueueSnackbar("Failed to logout", {
          autoHideDuration: 2000,
          variant: "error",
        });
      });
  };

  useEffect(() => {
    const fetchParentProfile = async () => {
      try {
        const response = await axios.get("/users/profile", {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        });
        setParent(response.data);
      } catch (err) {
        console.error(err);
      }
    };
    fetchParentProfile();
  }, [user]);

  const clearCache = () => {
    console.log(localStorage);
    console.log("clearing cache");
    localStorage.clear();
    console.log(localStorage);
  };

  const getToken = () => {
    console.log(user);
  };

  return (
    <div>
      {/* <NavBar /> */}
      <section className="flex flex-col md:mx-12 mx-6">
        <WaveTitle
          color="blue"
          title={t("parent_profile")}
          titleColor="black"
        />
        <div className="flex flex-row justify-center gap-96 mt-12">
          <div className="flex flex-col justify-start items-center mt-8 md:px-12 px-6">
            <div className="bg-pink-500 w-60 h-60 rounded-full flex items-center justify-center text-white font-semibold text-8xl mb-8">
              {firstLetter}
            </div>
            <button
              onClick={handleLogout}
              className="bg-primary hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full"
            >
              {t("buttons.logout")}
            </button>
          </div>
          <div className="flex flex-col justify-center items-center">
            <button
              onClick={clearCache}
              className="bg-primary hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full"
            >
              clear cache
            </button>
            <button
              onClick={getToken}
              className="bg-primary hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full"
            >
              token
            </button>
            <form className="flex flex-col items-center w-full gap-y-10">
              <div className="flex flex-row justify-center items-center w-full gap-2">
                <svg
                  className="w-6 h-6 text-darkgrey dark:text-white"
                  aria-hidden="true"
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    stroke="currentColor"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="2"
                    d="M10.779 17.779 4.36 19.918 6.5 13.5m4.279 4.279 8.364-8.643a3.027 3.027 0 0 0-2.14-5.165 3.03 3.03 0 0 0-2.14.886L6.5 13.5m4.279 4.279L6.499 13.5m2.14 2.14 6.213-6.504M12.75 7.04 17 11.28"
                  />
                </svg>
                <label className="relative inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={isToggled}
                    onChange={handleToggleChange}
                  />
                  <div className="w-9 h-5 bg-gray-200 hover:bg-gray-300 peer-focus:outline-0 peer-focus:ring-transparent rounded-full peer transition-all ease-in-out duration-500 peer-checked:after:translate-x-full peer-checked:after:border-white after:content-[''] after:absolute after:top-[2px] after:left-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-4 after:w-4 after:transition-all peer-checked:bg-blue-200 hover:peer-checked:bg-primary"></div>
                </label>
              </div>
              <div className="flex flex-col items-center w-full gap-y-6">
                <div className="flex flex-col">
                  <div className="w-full">
                    <input
                      type="text"
                      className="w-full border border-black border-opacity-20 py-2 px-4 rounded-full text-darkgrey font-semibold"
                      placeholder={parent.pseudo}
                      disabled={!isToggled}
                    />
                  </div>
                </div>
                <div className="flex flex-col">
                  <div className="w-full">
                    <input
                      type="email"
                      className="w-full border border-black border-opacity-20 py-2 px-4 rounded-full text-darkgrey font-semibold"
                      placeholder={parent.email}
                      disabled={!isToggled}
                    />
                  </div>
                </div>
                {isToggled && (
                  <>
                    <div className="flex flex-col">
                      <div className="w-full ">
                        <input
                          type="password"
                          className="w-full border border-black border-opacity-20 py-2 px-4 rounded-full text-darkgrey font-semibold"
                          placeholder={t("register.password")}
                        />
                      </div>
                    </div>
                    <div className="flex flex-col">
                      <div className="w-full ">
                        <input
                          type="password"
                          className="w-full border border-black border-opacity-20 py-2 px-4 rounded-full text-darkgrey font-semibold"
                          placeholder={t("register.password_confirmation")}
                        />
                      </div>
                    </div>
                    <button
                      onClick={getToken}
                      className="bg-primary hover:bg-blue-600 text-white font-bold py-2 px-4 rounded-full"
                    >
                      {t("buttons.save")}
                    </button>
                  </>
                )}
              </div>
            </form>
          </div>
        </div>
      </section>
    </div>
  );
};
