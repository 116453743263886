import React, { useState } from "react";
import Logo from "../assets/logo/logo_bassist.png";
import FamilyIllustration from "../assets/illustrations/family.png";
import Wave from "../assets/illustrations/waves/header-waves.png";
import axios from "../lib/config/axios";
import { useSnackbar } from "notistack";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";

export const Login = () => {
  const { login, user } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState({
    email: "",
    password: "",
  });

  if (user) {
    window.location.href = "/children";
  }

  const handleLogin = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (!form.email?.length || !form.password?.length) return;
    axios
      .post("/users/login", form)
      .then((response) => {
        if (response.status !== 200) throw new Error();
        login(response.data.access_token);
      })
      .catch(() => {
        enqueueSnackbar("Les identifiants sont incorrectes", {
          autoHideDuration: 2000,
          variant: "error",
        });
      });
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-row justify-center">
        {/* // ! Ah oui en effet... */}
        <img src={Wave} alt="Wave" />
        <img src={Wave} alt="Wave" className="hidden md:block" />
        <img src={Wave} alt="Wave" className="hidden md:block" />
        {/*  Display only on desktop screen */}
      </div>
      <div className="md:h-full w-full flex flex-row justify-center mt-6 md:mt-12 ">
        <div className="hidden md:flex h-full w-1/2 justify-center items-start">
          <img src={FamilyIllustration} alt="Family Illustration" />
        </div>
        <div className="md:w-1/2 flex justify-center items-start">
          <div className="w-full flex flex-col  items-center gap-y-10">
            <div className="flex justify-center w-full">
              <img src={Logo} alt="BA'SSIST Logo" className="w-2/3 md:w-1/4" />
            </div>
            <div className="flex justify-center w-full">
              <p className="text-2xl font-bold">{t("login.already_account")}</p>
            </div>
            <form
              className="flex flex-col items-center w-full gap-y-10"
              onSubmit={handleLogin}
            >
              <div className="flex flex-col items-center w-full gap-y-5">
                <div className="w-2/3 md:w-1/2 flex flex-col">
                  <div className="w-full">
                    <input
                      type="email"
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                      className="w-full border border-black border-opacity-20 py-2 px-4 rounded-full text-darkgrey font-semibold"
                      placeholder={t("login.email")}
                    />
                  </div>
                </div>
                <div className="w-2/3 md:w-1/2 flex flex-col">
                  <div className="w-full ">
                    <input
                      type="password"
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                      className="w-full border border-black border-opacity-20 py-2 px-4 rounded-full text-darkgrey font-semibold"
                      placeholder={t("login.password")}
                    />
                    {/* Add eye icon to see / unsee password */}
                  </div>
                </div>
              </div>
              <div className="flex justify-center w-full">
                <div className="flex items-center">
                  <input
                    id="remember-me"
                    name="remember-me"
                    type="checkbox"
                    className="h-4 w-4 accent-primary border-gray-300 rounded"
                  />
                  <label
                    htmlFor="remember-me"
                    className="ml-2 block text-sm text-gray-900 text-[17px]"
                  >
                    {t("login.remember_me")}
                  </label>
                </div>
              </div>
              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  className=" bg-primary hover:opacity-90 text-white font-bold py-2 px-20 rounded-full"
                >
                  {t("titles.login")}
                </button>
              </div>
            </form>
            <p>
              <a href="#" className="text-lg font-semibold">
                {t("login.password_forgotten")}
              </a>
            </p>
            <hr className="w-48 h-1 rounded bg-slate-400 " />
            <div className="flex justify-center w-full">
              <div className="flex flex-col items-center rounded-xl bg-white p-6">
                <p className="text-lg font-bold">{t("login.new_on_the_app")}</p>
                <a
                  href="/register"
                  className="text-primary text-lg font-bold capitalize"
                >
                  {t("login.sign_up")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
