import React, { useState, FormEvent } from "react";
import Logo from "../assets/logo/logo_bassist.png";
import FamilyIllustration from "../assets/illustrations/family.png";
import axios from "../lib/config/axios";
import { useSnackbar } from "notistack";
import { useAuth } from "../hooks/useAuth";
import { useTranslation } from "react-i18next";
import Wave from "../assets/illustrations/waves/header-waves.png";

interface FormState {
  email: string;
  pseudo: string;
  password: string;
  passwordConfirmation: string;
}

export const Register = () => {
  const { user } = useAuth();
  const { t } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();
  const [form, setForm] = useState<FormState>({
    email: "",
    pseudo: "",
    password: "",
    passwordConfirmation: "",
  });

  if (user) {
    window.location.href = "/children";
  }

  const handleRegister = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !form.email.length ||
      !form.pseudo.length ||
      !form.password.length ||
      form.password !== form.passwordConfirmation
    ) {
      return;
    }

    axios
      .post("/users/register", form)
      .then((response) => {
        if (response.status !== 201) throw new Error();
        localStorage.setItem("token", response.data.access_token);
        window.location.href = "/children";
      })
      .catch(() => {
        enqueueSnackbar("Une erreur est survenue", {
          autoHideDuration: 2000,
          variant: "error",
        });
      });
  };

  return (
    <div className="flex flex-col justify-center">
      <div className="flex flex-row justify-center">
        <img src={Wave} alt="Wave" />
        <img src={Wave} alt="Wave" className="hidden md:block" />
        <img src={Wave} alt="Wave" className="hidden md:block" />
      </div>
      <div className="md:h-full w-full flex flex-row justify-center mt-6 md:mt-12 ">
        <div className="hidden md:flex h-full w-1/2 justify-center items-center">
          <img src={FamilyIllustration} alt="Family Illustration" />
        </div>
        <div className="md:w-1/2 flex justify-center items-start">
          <div className="w-full flex flex-col items-center gap-y-8">
            <div className="flex justify-center w-full">
              <img src={Logo} alt="BA'SSIST Logo" className="w-2/3 md:w-1/4" />
            </div>
            <div className="flex justify-center w-full">
              <p className="text-2xl font-bold">{t("register.i_am_new")}</p>
            </div>
            <form
              className="flex flex-col items-center w-full gap-y-10"
              onSubmit={handleRegister}
            >
              <div className="flex flex-col items-center w-full gap-y-6">
                <div className="w-1/2 flex flex-col">
                  <div className="w-full">
                    <input
                      type="text"
                      onChange={(e) =>
                        setForm({ ...form, pseudo: e.target.value })
                      }
                      className="w-full border border-black border-opacity-20 py-2 px-4 rounded-full text-darkgrey font-semibold"
                      placeholder={t("register.pseudo")}
                    />
                  </div>
                </div>
                <div className="w-1/2 flex flex-col">
                  <div className="w-full">
                    <input
                      type="email"
                      onChange={(e) =>
                        setForm({ ...form, email: e.target.value })
                      }
                      className="w-full border border-black border-opacity-20 py-2 px-4 rounded-full text-darkgrey font-semibold"
                      placeholder={t("register.email")}
                    />
                  </div>
                </div>
                <div className="w-1/2 flex flex-col">
                  <div className="w-full ">
                    <input
                      type="password"
                      onChange={(e) =>
                        setForm({ ...form, password: e.target.value })
                      }
                      className="w-full border border-black border-opacity-20 py-2 px-4 rounded-full text-darkgrey font-semibold"
                      placeholder={t("register.password")}
                    />
                    {/* Add eye icon to see / unsee password */}
                  </div>
                </div>
                <div className="w-1/2 flex flex-col">
                  <div className="w-full ">
                    <input
                      type="password"
                      onChange={(e) =>
                        setForm({
                          ...form,
                          passwordConfirmation: e.target.value,
                        })
                      }
                      className="w-full border border-black border-opacity-20 py-2 px-4 rounded-full text-darkgrey font-semibold"
                      placeholder={t("register.password_confirmation")}
                    />
                    {/* Add eye icon to see / unsee password */}
                  </div>
                </div>
              </div>

              <div className="flex justify-center w-full">
                <div className="flex flex-col gap-y-3">
                  <div className="flex items-center">
                    <input
                      id="terms-conditions"
                      name="terms-conditions"
                      type="checkbox"
                      className="h-4 w-4 accent-primary border-gray-300 rounded"
                    />
                    <label
                      htmlFor="terms-conditions"
                      className="ml-2 block text-sm text-gray-900 text-[17px]"
                    >
                      {t("register.conditions1")}{" "}
                      <span className="underline">
                        {t("register.conditions2")}
                      </span>
                    </label>
                  </div>
                  <div className="flex items-center">
                    <input
                      id="remember-me"
                      name="remember-me"
                      type="checkbox"
                      className="h-4 w-4 accent-primary border-gray-300 rounded"
                    />
                    <label
                      htmlFor="remember-me"
                      className="ml-2 block text-sm text-gray-900 text-[17px]"
                    >
                      {t("register.remember_me")}
                    </label>
                  </div>
                </div>
              </div>
              <div className="flex justify-center w-full">
                <button
                  type="submit"
                  className=" bg-primary hover:opacity-90 text-white font-bold py-2 px-20 rounded-full"
                >
                  {t("titles.register")}
                </button>
              </div>
            </form>
            <hr className="w-48 h-1 rounded" />
            <div className="flex justify-center w-full">
              <div className="flex flex-col items-center rounded-xl bg-white p-6">
                <p className="text-lg font-bold">
                  {t("register.already_account")}
                </p>
                <a
                  href="/login"
                  className="text-primary text-lg font-bold capitalize"
                >
                  {t("titles.login")}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
