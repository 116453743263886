import { t } from "i18next";
import "./PharmacyBox.scss";
import { useEffect, useState } from "react";
import axios from "../../lib/config/axios";
import { useAuth } from "../../hooks/useAuth";
import MedicationCard from "./components/MedicationCard/MedicationCard";
import { MedicationCartegoryType, MedicationsType } from "./PharmacyBox.d";
import Modal from "../../component/Modal/Modal";
import { enqueueSnackbar } from "notistack";

type ModalType = {
  view: boolean;
  type: string;
  medication?: MedicationsType;
};

type EditMedication = {
  expiryDate?: string;
  quantity?: number;
};

const PharmacyBox = () => {
  const [cip, setCip] = useState("");
  const [medications, setMedications] = useState<MedicationCartegoryType[]>();
  const [viewModal, setViewModal] = useState<ModalType>({
    view: false,
    type: "",
    medication: undefined,
  });
  const [editMedication, setEditMecication] = useState<EditMedication>({
    expiryDate: undefined,
    quantity: undefined,
  });

  const { user } = useAuth();

  const createMedications = (medicationList: MedicationsType[]) => {
    const list: MedicationCartegoryType[] = medicationList.reduce(
      (acc: any, med: any) => {
        const idxCategory = acc.findIndex(
          (elem: MedicationCartegoryType) =>
            med.medicine.category === elem.category
        );
        if (idxCategory > -1) {
          const res = acc.map((elem: MedicationCartegoryType, idx: number) =>
            idx === idxCategory
              ? {
                  category: elem.category,
                  medications: [...(elem.medications ?? []), med],
                }
              : elem
          );
          return res;
        } else {
          return [
            ...acc,
            { category: med.medicine.category, medications: [med] },
          ];
        }
      },
      []
    );
    return list;
  };

  useEffect(() => {
    axios
      .get("/pharmacy-boxes/medicines", {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        const medicationList = createMedications(res.data.medicines);
        setMedications(medicationList);
      })
      .catch((err) => console.error(err));
  }, [cip]);

  const onValidateCIP = () => {
    axios
      .get(`/medicines/${cip}`, {
        headers: {
          Authorization: `Bearer ${user}`,
        },
      })
      .then((res) => {
        const medication = res.data;
        axios
          .post(
            `/pharmacy-boxes/medicines`,
            {
              medicineId: medication.id,
              quantity: 1,
            },
            {
              headers: {
                Authorization: `Bearer ${user}`,
              },
            }
          )
          .then((res) => {
            const medicationList = createMedications(res.data.medicines);
            setMedications(medicationList);
          })
          .catch((err) => {
            const status = err.response.status;
            if (status === 409)
              enqueueSnackbar(t("pharmacy_box.add_error"), {
                autoHideDuration: 2000,
                variant: "error",
              });
          });
        setCip("");
      })
      .catch((err) => console.error(err));
  };

  const onDelete = (medication?: MedicationsType) => {
    medication &&
      axios
        .delete(`/pharmacy-boxes/medicines/${medication.medicineId}`, {
          headers: {
            Authorization: `Bearer ${user}`,
          },
        })
        .then(() => {
          const result: MedicationCartegoryType[] = medications?.reduce(
            (acc: any, category) => {
              const newList = category.medications.filter(
                (med) => med.id !== medication.id
              );
              if (newList.length > 0) {
                acc.push({
                  ...category,
                  medications: newList,
                });
              }
              return acc;
            },
            []
          );
          setMedications(result);
          setViewModal({ view: false, type: "", medication: undefined });
        });
  };

  const onEditMedication = (medication?: MedicationsType) => {
    const tabExpiryDate = editMedication.expiryDate?.split("-");
    const transformedDate =
      tabExpiryDate &&
      new Date(
        Date.UTC(parseInt(tabExpiryDate[0]), parseInt(tabExpiryDate[1]) - 1, 1)
      );
    medication &&
      axios
        .patch(
          `/pharmacy-boxes/medicines/${viewModal.medication?.medicineId}`,
          {
            ...(editMedication.expiryDate
              ? { expiryDate: transformedDate?.toISOString() }
              : {}),
            quantity: editMedication.quantity ?? medication.quantity,
          },
          {
            headers: {
              Authorization: `Bearer ${user}`,
            },
          }
        )
        .then((res) => {
          const medicationList = createMedications(res.data.medicines);
          setMedications(medicationList);
          setViewModal({ type: "", view: false, medication: undefined });
        });
  };

  const openViewModal = (medication: MedicationsType) => {
    const date = new Date(medication.expiryDate);
    const year = date?.getUTCFullYear();
    const month = String(date?.getUTCMonth() + 1).padStart(2, "0");
    setEditMecication({
      expiryDate: `${year}-${month}` ?? undefined,
      quantity: medication.quantity,
    });
    setViewModal({
      view: true,
      type: "edit",
      medication,
    });
  };

  return (
    <div className="pharmacy_box">
      {/* <NavBar /> */}
      <section>
        <div>
          <div className="title">
            <img
              src="/static/media/wave-blue.5f2da6276d4010867ab6.png"
              alt="bluebackground wave"
            />
            <h1>{t("children.pharmacy_box")}</h1>
          </div>
          <div className="cip">
            <input
              placeholder={t("pharmacy_box.code")}
              onChange={(e) => setCip(e.target.value)}
              value={cip}
            />
            <button onClick={onValidateCIP}>{t("buttons.add")}</button>
          </div>
        </div>
        <div>
          {medications?.map((medicationCategory, idx) => (
            <div className="medication-element" key={idx}>
              <p>{medicationCategory.category}</p>
              <div>
                {medicationCategory.medications.map((medication) => (
                  <MedicationCard
                    {...medication}
                    onClick={() => openViewModal(medication)}
                    onDelete={() =>
                      setViewModal({ view: true, type: "delete", medication })
                    }
                  />
                ))}
              </div>
            </div>
          ))}
        </div>
      </section>
      {viewModal.view && (
        <Modal
          size="medium"
          content={
            viewModal.type === "edit" ? (
              <div className="content-month">
                <h1>{t("pharmacy_box.edit_medication")}</h1>
                <div className="data-content">
                  <div>
                    <p>{t("pharmacy_box.input_expery_date")}</p>
                    <input
                      type="month"
                      value={editMedication?.expiryDate}
                      onChange={(e) =>
                        setEditMecication({
                          expiryDate: e.target.value,
                          quantity: editMedication.quantity,
                        })
                      }
                    />
                  </div>
                  <div>
                    <p>{t("pharmacy_box.input_number_box")}</p>
                    <input
                      type="number"
                      value={editMedication?.quantity}
                      onChange={(e) =>
                        setEditMecication({
                          expiryDate: editMedication.expiryDate,
                          quantity: parseInt(e.target.value),
                        })
                      }
                    />
                  </div>
                </div>
                <div className="button-validate">
                  <button
                    onClick={() => onEditMedication(viewModal?.medication)}
                  >
                    {t("buttons.validate").toUpperCase()}
                  </button>
                </div>
              </div>
            ) : (
              <div className="content-delete">
                <h1>{t("pharmacy_box.delete")}</h1>
                <div className="data-content">
                  <p>
                    {t("pharmacy_box.delete_medication", {
                      name: viewModal.medication?.medicine.name,
                      category: viewModal.medication?.medicine.category,
                    })}
                  </p>
                </div>
                <div className="button-validate">
                  <button onClick={() => onDelete(viewModal?.medication)}>
                    {t("buttons.validate").toUpperCase()}
                  </button>
                </div>
              </div>
            )
          }
          onClose={() =>
            setViewModal({ view: false, type: "", medication: undefined })
          }
        />
      )}
    </div>
  );
};

export default PharmacyBox;
